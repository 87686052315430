export const CAMPOS = {
    IDENTIFICADOR: 'identificador',
    CODIGO: 'codigo',
};

export const CONTEXTO = {
    EXAME: 'exame',
    LAUDO: 'laudo',
    APAC: 'apac'
};
