import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttExternal as SttExterno,
    SttHeading,
    SttAlerta,
    SttLoading,
    SttGrid
} from '@stt-componentes/core';
import alerta from '../signals/alerta';
import { useSignals } from '@preact/signals-react/runtime';
import carregando from '../signals/carregando';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './home';
import Exame from './exame';
import Apac from './apac';
import Laudo from './laudo';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    heading: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '2rem',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem'
        }
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const Validador = () => {
    const classes = useStyles();

    useSignals();

    return (
        <>
            <SttExterno
                instancia={{
                    logoHeader: global.gConfig.logo_header,
                    logoFooter: global.gConfig.logo_footer
                }}
                basename={global.gConfig.basename}
            >
                <div className={classes.wrapper}>

                    <SttGrid className={classes.grid}>
                        <SttHeading
                            variant="h1"
                            color="primary"
                            className={classes.heading}
                        >
                            {global.gConfig.instancia_nome}
                        </SttHeading>

                        <Routes>
                            <Route path="*" element={<Navigate to='/exame' replace />} />
                            {/* <Route path="/laudo" exact element={<Laudo />} /> */}
                            <Route path="/exame" exact element={<Exame />} />
                            <Route path="/apac" exact element={<Apac />} />
                            {/* <Route path="/" exact element={<Home />} /> */}
                        </Routes>
                    </SttGrid>
                </div>
            </SttExterno>

            <SttAlerta {...alerta.value} />
            <SttLoading {...carregando.value} />
        </>
    );
};

export default Validador;
